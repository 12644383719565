@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "TWKEverett";
  src: url("/fonts/TWKEverett/TWKEverett-Light.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TWKEverett";
  src: url("/fonts/TWKEverett/TWKEverett-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TWKEverett";
  src: url("/fonts/TWKEverett/TWKEverett-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

html,
body {
  max-width: 100vw;
  height: 100dvh;
  scroll-behavior: smooth;
	@apply bg-gray-50;
}

div#__next {
  height: 100dvh;
  /* display: flex;
  flex-direction: column; */
}

a {
  color: inherit;
  text-decoration: none;
}

iframe {
  border-radius: 0 !important;
}
